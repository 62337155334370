#scrollChange::-webkit-scrollbar-track {
    background: #D0C8B9; 
}
#scrollChange::-webkit-scrollbar {
    width: 7px;
}
#scrollChange::-webkit-scrollbar-thumb {
    background: #414141;
} 
#scrollChange::-webkit-scrollbar-thumb:hover {
    background: #414141;
}
.addRoomGuest p{
    color: #fff;
    display: inline-block;
    margin-top: 10px;
}
.applyFilterDisabled{
    opacity: 40%;
}
.applyFilter{
    opacity: 100%;
}
.travel{
    background-color: #17202B;
    color: #fff;
    padding: 20px 30px;
}
.travel .currencySignUp a{
    color: #fff;
    text-decoration: none;
    margin: 15px 15px 0px 25px;
}
.travel .currencySignUp{
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
}
.roomDetails{
    padding: 15px 30px;
    min-height: 100%;
    position: absolute;
    top: 60px;
    left: 0px;
    right: 0px;
    background-color: #17202B;
}
.roomDetails .tableData{
    background-color: #526781; 
    color: #fff;
    border-radius: 6pt;
    width: 100%;
    margin-top: 30px;
}
.roomDetails .tableData h6{
    font-size: 12px;
    color: #fff;
}
.roomDetails .tableData tr td{
    color: #fff;
    border-bottom: none;
    font-size: 14px;
}
.roomDetails .tableData tr td span{
    font-size: 12px;
}
.addAnotherRoom{
    margin-top: 30px;
    color: #2952DD;
    font-size: 18px;
}
.addAnotherRoom span:nth-child(2){
    font-size: 18px;
    color: #2952DD;
    padding-left: 10px;
}
.removeRoom{
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 0.39;
    color: #2952DD;
    font-size: 14px;
    background-color: #fff;
    width: 110px;
    height: 28px;
    display: block;
    text-decoration: none;
    padding-top: 8px;
    float: right;
    text-align: center;
}
.done{
    width: 100%;
    font-size: 18px !important;
    letter-spacing: 0.36px !important;
    background-color: #526781 !important;
    border-radius: 4px !important;
    color: #fff !important;
    margin-top: 30px !important;
    padding: 20px 0px !important;
}
.done:hover{
    background-color: #2952DD;
}
/* mobile filter */
/* .starRatingIcon {
    border: 1px solid #A3A3A3;
    padding: 15px;
    width: 40px !important;
    height: 40px !important;
    background: rgb(82, 103, 129);
    color: #fff !important;
    font-size: 28px !important;
    display: flex !important;
    gap: 10px !important;
} */
/* mobile filter */

/* select rate css */

.selectRateStyle{
    text-overflow: ellipsis;
    overflow: visible;
    width: max-content;
    margin-top: 20px;
}
.selectRateStyleDLF{
    text-overflow: ellipsis;
    overflow: visible;
    width: max-content;
    margin-top: 3px;
}
.MuiInputBase-root input[type='text']{
    background-color: transparent !important;
    border: 0px !important;
    border: none !important;
}
.selectRateStyle .selectRateName{
    position: relative;
    width: 80px;
    font-size: 14px;
    top: 9px;
    font-weight: 400; 
    cursor: pointer;
}
.selectRateStyle .selectRateName span{
    font-size: 14px;
    text-transform: initial;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 135px;
    display: block;
    white-space: nowrap;
}
.promoteRate{
    display: flex;
}
.promoteRate p{
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    /* padding-left: 30px !important; */
    line-height: 2;
}
.promoteRate .clear{
    position: absolute;
    top: 25px;
    right: 50px;
    font-size: 15px;
    cursor: pointer;
}
.apply{
    font-size: 12px !important;
    text-transform: uppercase !important;
    width: 70px !important;
    height: 30px !important;
    margin-top: 20px !important;
    padding: 0px !important;
}
.applyDLF{
    font-size: 12px !important;
    text-transform: uppercase !important;
    width: 70px !important;
    height: 30px !important;
    margin-top: 14px !important;
    padding: 0px !important;
}
.promoteRate .clearCode{
    position: absolute;
    font-size: 15px;
    cursor: pointer;
    top: 25px;
}
.hoverDetails h3{
    font-size: 14px;
    color: #17202B;
    font-weight: 700;
    padding-bottom: 10px;
}
.hoverDetails span{
    color: #17202B;
    font-size: 14px;
    font-weight: 400;
}
.alignInfo{
    padding: 5px 15px;
}
/* select rate css end */
/* mobile select rate css */

.mobileSelectRate{
    background-color: #fff;
    position: absolute;
    z-index: 9;
    width: 86%;
    margin-left: -16px;
    border-radius: 4pt;
    border: 1px solid #E4E4E4;
    box-shadow: 1px 1px 1px #00000029;
    margin-top: -60px;
}
.mobileSelectRate .infoIcon{
    color: #A8A8A8;
    margin: 15px 10px;
}
.mobileSelectRate .alignInfo{
    display: flex;
    justify-content: space-between;
}
.vertLine{
    width: 100%;
    margin: 0px;
    opacity: 30%;
}
.mobileSelectRate .promoteRate .promoteText{
    padding-left: 0px !important;
    margin-left: 30px;
}
.mobileSelectRate .promoteRate .corporateText{
    display: flex;
    justify-content: flex-start;
}
.mobileSelectRate .promoteRate .apply{
    margin-top: 15px !important;
} 
.arrangeCodeBoxes {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
}
/* mobile select rate css end */