export const cityCodeMapping = [
    { name: "Shanghai", code: "RGCNSH4" },
    { name: "London", code: "RGGBLO5" },
    { name: "Paris", code: "RGFRPA6" },
    { name: "Berlin", code: "RGDEBE7" },
    { name: "Bali", code: "RGIDBA8" },
    { name: "Jakarta", code: "RGIDJA9" },
    { name: "Rome", code: "RGITRO11" },
    { name: "Amsterdam", code: "RGNLAM12" },
    { name: "Singapore", code: "RGSGSI13" },
    { name: "Barcelona", code: "RGESBA14" },
    { name: "Madrid", code: "RGESMD15" },
    { name: "Bangkok", code: "RGTHBA16" },
    { name: "Istanbul", code: "RGTRIS17" },
    { name: "Dubai", code: "RGAEDU18" },
    { name: "Chicago", code: "RGUSCI19" },
    { name: "Austin", code: "RGUSAU21" },
    { name: "Dallas", code: "RGUSDA24" },
    { name: "Houston", code: "RGUSHO25" },
    { name: "Jacksonville", code: "RGUSJA27" },
    { name: "Las Vegas", code: "RGUSLV28" },
    { name: "Miami", code: "RGUSMI29" },
    { name: "Nashville", code: "RGUSNA30" },
    { name: "New York", code: "RGUSNY31" },
    { name: "Orlando", code: "RGUSOL32" },
    { name: "Phoenix", code: "RGUSPH33" },
    { name: "San Antonio", code: "RGUSSA34" },
    { name: "San Francisco", code: "RGUSSF35" },
    { name: "Washington", code: "RGUSWA36" },
    { name: "Geneva", code: "RGCHGE42" },
    { name: "Mexico City", code: "RGMXME47" },
    { name: "Stockholm", code: "RGSEST55" },
    { name: "Casablanca", code: "RGMACA58" },
    { name: "Tokyo", code: "RGJPTO63" },
    { name: "Cancun", code: "RGMXCA68" },
    { name: "Mumbai", code: "RGINMU75" },
    { name: "Reykjavik", code: "RGISRE77" },
    { name: "Abu Dhabi", code: "RGAEAB89" },
    { name: "Sydney", code: "RGAUSY104" },
    { name: "Delhi", code: "RGINDE106" },
    { name: "St-denis", code: "RGREST116" },
    { name: "Mauritius", code: "RGMUMA128" },
    { name: "Doha", code: "RGQADO136" },
    { name: "Manila", code: "RGPHMA152" },
    { name: "Tenerife", code: "RGESTE170" },
    { name: "Bengaluru", code: "RGINBE200" },
    { name: "Philadelphia", code: "RGUSPH208" },
    { name: "Oslo", code: "RGNOOS217" },
    { name: "Muscat", code: "RGOMMU220" },
    { name: "Melbourne", code: "RGAUME224" },
    { name: "San Diego", code: "RGUSSD241" },
    { name: "Gran Canaria", code: "RGESGR289" },
    { name: "Ibiza", code: "RGESIB290" },
    { name: "Malaga", code: "RGDOPU291" },
    { name: "Alicante", code: "RGESSA292" },
    { name: "Mallorca", code: "RGESA 293" },
    { name: "Fuerteventura", code: "RGESME295" },
    { name: "Crete", code: "RGESVI296" },
    { name: "Corfu", code: "RGESFU297" },
    { name: "Savannah", code: "RGUSSA499" },
    { name: "Fargo", code: "RGUSFA514" },
    { name: "Trondheim", code: "RGNOTR612" },
    { name: "Almeria", code: "RGESAL648" },
    { name: "Menorca", code: "RGESME653" },
    { name: "Calgary", code: "RGCACA666" },
    { name: "Puerto Vallarta", code: "RGMXPU695" },
    { name: "Phuket", code: "RGTHPH697" },
    { name: "San Jose", code: "RGCRSA729" },
    { name: "Santo Domingo (DO)", code: "RGDOSA732" },
    { name: "Lanzarote", code: "RGESLA811" },
    { name: "Sharm El-Sheikh", code: "RGEGSH835" },
    { name: "Stavanger", code: "RGNOST864" },
    { name: "Bergen", code: "RGNOBE866" },
    { name: "Merida (MX)", code: "RGMXME967" },
    { name: "Grand Forks", code: "RGUSGR988" },
    { name: "Brisbane", code: "RGAUBR1059" },
    { name: "Dublin", code: "RGIEDU1071" },
    { name: "Auckland", code: "RGNZAU1130" },
    { name: "Langkawi", code: "RGMYLA1175" },
    { name: "Kristiansand", code: "RGNOKR1186" },
    { name: "Tromso", code: "RGNOTR1330" },
    { name: "Queenstown", code: "RGNZQU1924" },
    { name: "Punta Cana", code: "RGDOPU2063" },
    { name: "Drammen", code: "RGNODR15017" },
    { name: "Costa Blanca", code: "RGESCO15286" },
    { name: "Costa del Sol", code: "RGESCO15288" },
    { name: "Algarve", code: "RGPTAL15294" },
    { name: "Costa Brava", code: "RGESCO15328" },
    { name: "Reykjanesbaer", code: "RGISRE15330" },
    { name: "Cadiz", code: "RGESCA15331" },
    { name: "Male", code: "RGMVMA15426" },
    { name: "Valencia", code: "RGESVA15967" },
    { name: "Manama", code: "RGBHMA16491" }
];
