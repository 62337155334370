// Please use Unique Names
export enum Features
{
	DEMAND_CALENDAR = 'DEMAND_CALENDAR',
}
interface IFeatureConfig
{
    name:Features,
    author: string,
    devCompletionDate: string,
    dev: boolean,
    qa: boolean,
    preprod: boolean,
    live: boolean
}

const FeaturesConfig: Array<IFeatureConfig> = [
    {
        name:Features.DEMAND_CALENDAR,
        author: 'AYUSH',
        devCompletionDate: '',
        dev: true,
        qa: true,
        preprod: true,
        live: true
    }
]

export const IsFeatureEnabled = (feature: Features): any =>{
    const params = window.location.href.split('?');
    const config = FeaturesConfig.find((config) => config.name === feature);
    const environmentName = process.env.NEXT_PUBLIC_ENV_NAME

    if(params?.length > 1 && params[1].toLowerCase()?.includes(feature.toLowerCase()))
        return true

    if (!config) {
        console.log(`Feature "${feature}" is not defined.`);
        return false;
    }

    switch (environmentName) {
        case 'dev':
            return config.dev;
        case 'qa':
            return config.qa;
        case 'preprod':
            return config.preprod;
        case 'live':
            return config.live;
        default:
            console.log(`Invalid environment name: "${environmentName}".`);
            return <></>;
    }
}