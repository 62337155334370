import React, { useState, useEffect } from 'react';
import { Box, ClickAwayListener, FormControl, Typography, Tooltip, InputAdornment, CircularProgress } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from './../addRoom.module.css';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import usePromotion from './usePromotion';
import { createGlobalStyle } from 'styled-components';

export default function ApplyFilterComponent(props:any) {
    const { setFieldValue, isDisable,brandID, widgetValues, fontColor, booknowbuttoncolor, booknowbuttonhovercolor, buttonFontColor, buttonHoverFontColor, promotionData, isPromotionLoading, isPageLoaded, setEventType, eventType} = props;

    const { promotionOption,
            getSelectedPromotionText,
            handleCloseFilter,
            handleChange,
            handleClear,
            handleFilterOpen,
            selectedPromotion,
            applyPromotion,
            open,
            error,
            boxHidden,
            onCodeChange,
            getPromotionTooltip,
            loader,
            promotionFormIk } = usePromotion(setFieldValue, false, false, promotionData, widgetValues, isPageLoaded, setEventType, eventType);

    const styleError = {
        '& .MuiFormHelperText-root.Mui-error': {
            color: '#F21919 !important',
            fontSize: '10px',
            paddingLeft: '0px !important',
            fontFamily: 'sans-serif',
            width: '100%',
        }
    }

    const promotionStyleDlf = {
        // text-overflow: ellipsis;
        // overflow: visible;
        // width: max-content;
        // margin-top: 3px;
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 'max-content',
        marginTop: '6px'
    }
    const promotionStyleOther: any = {
        position: 'relative',
        width: 'max-content',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        marginTop: '20px'
    }

    const [showTooltip, setShowTooltip] = useState(false);

    const GlobalStyle = createGlobalStyle`
    #scrollChange::-webkit-scrollbar-track {
        background: #D0C8B9;
    }
    #scrollChange::-webkit-scrollbar {
        width: 7px;
    }
    #scrollChange::-webkit-scrollbar-thumb {
        background: #414141;
    }
    #scrollChange::-webkit-scrollbar-thumb:hover {
        background: #414141;
    }
    .addRoomGuest p{
        color: #fff;
        display: inline-block;
        margin-top: 10px;
    }
    .applyFilterDisabled{
        opacity: 40%;
    }
    .applyFilter{
        opacity: 100%;
    }
    .travel{
        background-color: #17202B;
        color: #fff;
        padding: 20px 30px;
    }
    .travel .currencySignUp a{
        color: #fff;
        text-decoration: none;
        margin: 15px 15px 0px 25px;
    }
    .travel .currencySignUp{
        padding-top: 5px;
        display: flex;
        justify-content: flex-end;
    }
    .roomDetails{
        padding: 15px 30px;
        min-height: 100%;
        position: absolute;
        top: 60px;
        left: 0px;
        right: 0px;
        background-color: #17202B;
    }
    .roomDetails .tableData{
        background-color: #526781;
        color: #fff;
        border-radius: 6pt;
        width: 100%;
        margin-top: 30px;
    }
    .roomDetails .tableData h6{
        font-size: 12px;
        color: #fff;
    }
    .roomDetails .tableData tr td{
        color: #fff;
        border-bottom: none;
        font-size: 14px;
    }
    .roomDetails .tableData tr td span{
        font-size: 12px;
    }
    .addAnotherRoom{
        margin-top: 30px;
        color: #2952DD;
        font-size: 18px;
    }
    .addAnotherRoom span:nth-child(2){
        font-size: 18px;
        color: #2952DD;
        padding-left: 10px;
    }
    .removeRoom{
        border: 1px solid #707070;
        border-radius: 6px;
        opacity: 0.39;
        color: #2952DD;
        font-size: 14px;
        background-color: #fff;
        width: 110px;
        height: 28px;
        display: block;
        text-decoration: none;
        padding-top: 8px;
        float: right;
        text-align: center;
    }
    .done{
        width: 100%;
        font-size: 18px !important;
        letter-spacing: 0.36px !important;
        background-color: #526781 !important;
        border-radius: 4px !important;
        color: #fff !important;
        margin-top: 30px !important;
        padding: 20px 0px !important;
    }
    .done:hover{
        background-color: #2952DD;
    }
    /* mobile filter */
    /* .starRatingIcon {
        border: 1px solid #A3A3A3;
        padding: 15px;
        width: 40px !important;
        height: 40px !important;
        background: rgb(82, 103, 129);
        color: #fff !important;
        font-size: 28px !important;
        display: flex !important;
        gap: 10px !important;
    } */
    /* mobile filter */

    /* select rate css */

    .selectRateStyle{
        text-overflow: ellipsis;
        overflow: visible;
        width: max-content;
        margin-top: 20px;
    }
    .selectRateStyleDLF{
        text-overflow: ellipsis;
        overflow: visible;
        width: max-content;
        margin-top: 3px;
    }
    .MuiInputBase-root input[type='text']{
        background-color: transparent !important;
        border: 0px !important;
        border: none !important;
    }
    .selectRateStyle .selectRateName{
        position: relative;
        width: 80px;
        font-size: 14px;
        top: 9px;
        font-weight: 400;
        cursor: pointer;
    }
    .selectRateStyle .selectRateName span{
        font-size: 14px;
        text-transform: initial;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 135px;
        display: block;
        white-space: nowrap;
    }
    .promoteRate{
        display: flex;
    }
    .promoteRate p{
        margin-top: 0px;
        margin-bottom: 0px;
        position: relative;
        /* padding-left: 30px !important; */
        line-height: 2;
    }
    .promoteRate .clear{
        position: absolute;
        top: 25px;
        right: 50px;
        font-size: 15px;
        cursor: pointer;
    }
    .apply{
        font-size: 12px !important;
        text-transform: uppercase !important;
        width: 70px !important;
        height: 30px !important;
        margin-top: 20px !important;
        padding: 0px !important;
    }
    .applyDLF{
        font-size: 12px !important;
        text-transform: uppercase !important;
        width: 70px !important;
        height: 30px !important;
        margin-top: 14px !important;
        padding: 0px !important;
    }
    .promoteRate .clearCode{
        position: absolute;
        font-size: 15px;
        cursor: pointer;
        top: 25px;
    }
    .hoverDetails h3{
        font-size: 14px;
        color: #17202B;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .hoverDetails span{
        color: #17202B;
        font-size: 14px;
        font-weight: 400;
    }
    .alignInfo{
        padding: 5px 15px;
    }
    /* select rate css end */
    /* mobile select rate css */

    .mobileSelectRate{
        background-color: #fff;
        position: absolute;
        z-index: 9;
        width: 86%;
        margin-left: -16px;
        border-radius: 4pt;
        border: 1px solid #E4E4E4;
        box-shadow: 1px 1px 1px #00000029;
        margin-top: -60px;
    }
    .mobileSelectRate .infoIcon{
        color: #A8A8A8;
        margin: 15px 10px;
    }
    .mobileSelectRate .alignInfo{
        display: flex;
        justify-content: space-between;
    }
    .vertLine{
        width: 100%;
        margin: 0px;
        opacity: 30%;
    }
    .mobileSelectRate .promoteRate .promoteText{
        padding-left: 0px !important;
        margin-left: 30px;
    }
    .mobileSelectRate .promoteRate .corporateText{
        display: flex;
        justify-content: flex-start;
    }
    .mobileSelectRate .promoteRate .apply{
        margin-top: 15px !important;
    }
    .arrangeCodeBoxes {
        display: grid;
        grid-auto-flow: column;
        gap: 20px;
    }
    `

    return (
        <ClickAwayListener onClickAway={handleCloseFilter}>
            <form>
                <GlobalStyle />
                <Box component="div">
                    <Box component='div' style={brandID =='ba94d9bb-d072-4fa8-8b84-b51783cd0a55' ? {...promotionStyleDlf} : {...promotionStyleOther}} >
                        <Box component="span" className='selectRateNameDLF' sx={{ color: `${fontColor} !important`, '&:hover' :  { cursor: !isDisable || isPromotionLoading ? 'default' : 'pointer'} }}
                            id="IBE_AddGuest_Pos" onClick={ !isDisable || isPromotionLoading ? () => {} : handleFilterOpen }>
                                <span>{getSelectedPromotionText(widgetValues?.promotion?.promotionType, widgetValues?.promotion?.promotionCode1) }</span>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: '#fff', display: open ? 'block' : 'none',
                            width: 'max-content', marginTop : '16px', ml: '-20px',
                            boxShadow: '0 3px 6px #00000029', borderRadius: '5px', position: 'relative', zIndex:999
                        }}
                    >
                        <Box>
                            <FormControl>
                                <RadioGroup value={selectedPromotion} onChange={handleChange} sx={{width: '345px', '& .Mui-checked': { color: `${booknowbuttoncolor} !important` }}}>
                                    {promotionOption && promotionOption.map((promotionDetail: any, index : number) =>
                                        {
                                            return (
                                                <>

                                                <Box component="div" className='alignInfo'>
                                                {open &&
                                                <Tooltip arrow
                                                    title={
                                                        <>
                                                            <Box component="div" className='hoverDetails'>
                                                                <Typography variant="h3">{promotionDetail?.promoCatName}</Typography>
                                                                <Box component="span">
                                                                    { promotionDetail?.description }
                                                                </Box>
                                                            </Box>
                                                        </>} placement='left-start'
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: '#FFFDE6',
                                                                width: '300px',
                                                                border: '1px solid #D5D5D5',
                                                                padding: '20px',
                                                                top: '5px',
                                                                '& .MuiTooltip-arrow': {
                                                                    color: '#FFFDE6',
                                                                    fontSize: '16px',
                                                                    // top: '40px !important'
                                                                },
                                                                '& .MuiTooltip-arrow::before': {
                                                                    border: '1px solid #D5D5D5'
                                                                },
                                                            },
                                                        },
                                                    }}>
                                                    <FormControlLabel
                                                        disabled={loader}
                                                        value={promotionDetail?.key}
                                                        onChange={handleChange}
                                                        control={<Radio size='small' sx={{ color: `${booknowbuttoncolor} !important` }} />}
                                                        label={<span style={{ fontSize: '14px', color: '#000'}}>{promotionDetail?.promoCatName}</span>}
                                                        ></FormControlLabel>
                                                </Tooltip>
                                                }
                                                {promotionDetail?.key == selectedPromotion && promotionDetail?.subCategories?.length > 0 &&
                                                    <Box component="div" className='promoteRate' sx={{ml:'30px'}}>
                                                        <Box component="p" className='arrangeCodeBoxes'>
                                                        {promotionDetail?.subCategories?.map((subCategory: any, index: number) =>
                                                        <>
                                                {/* <Box component={'span'} sx={{color: 'black'}}>{JSON.stringify(promotionDetail?.subCategories.length)}</Box> */}

                                                            <TextField
                                                                key={subCategory?.promoScId}
                                                                id={subCategory?.promoScId}
                                                                label={subCategory?.pmScName}
                                                                name={`codeValues.${[`code_${index + 1}`]}`}
                                                                type='text'
                                                                onChange={(e) => {
                                                                    promotionFormIk.handleChange(e);
                                                                    onCodeChange(`code_${index + 1}`, promotionFormIk);
                                                                }}
                                                                onBlur={promotionFormIk.handleBlur}
                                                                value={promotionFormIk.values?.codeValues?.[`code_${index + 1}`]}
                                                                error={error?.[`code_${index + 1}`]?.isValid || ( promotionFormIk.values?.codeValues?.[`code_${index + 1}`] ? false : promotionFormIk.touched?.codeValues?.[`code_${index + 1}`] && Boolean(promotionFormIk.errors?.codeValues?.[`code_${index + 1}`]))}
                                                                helperText={<>{error?.[`code_${index + 1}`]?.isValid ? error?.[`code_${index + 1}`]?.errorMessage : promotionFormIk.values?.codeValues?.[`code_${index + 1}`] ? null : promotionFormIk.touched?.codeValues?.[`code_${index + 1}`] && promotionFormIk.errors?.codeValues?.[`code_${index + 1}`] ? promotionFormIk.errors?.codeValues?.[`code_${index + 1}`] : null}</>}
                                                                sx={{
                                                                        border: 'none !important', paddingLeft: '0px !important',
                                                                        ...styleError,
                                                                        backgroundColor:'transparent  !important',
                                                                        position: 'relative',
                                                                        width: '100%',
                                                                        '& .MuiInputBase-input':{border: 'none !important', paddingLeft: '0px !important'},
                                                                        '& .MuiInputLabel-root.Mui-focused': {color: error?.[`code_${index + 1}`]?.isValid || promotionFormIk.errors?.codeValues?.[`code_${index + 1}`] ? 'red':  `${booknowbuttoncolor} !important`},
                                                                        '& .MuiInputBase-root.Mui-focused:after': {borderBottom: error?.[`code_${index + 1}`]?.isValid || promotionFormIk.errors?.codeValues?.[`code_${index + 1}`] ? 'red': `1px solid ${booknowbuttoncolor} !important`}
                                                                    }}

                                                                InputLabelProps={{ style: {color: 'rgba(0,0,0,0.6)', fontSize: '12px', textTransform: 'capitalize', zIndex: 1 }}}
                                                                InputProps={{
                                                                    endAdornment: promotionFormIk.values?.codeValues?.[`code_${index + 1}`] && (
                                                                    <InputAdornment position="start">
                                                                        <CancelIcon sx={{
                                                                            marginRight: '-8px',
                                                                            fontSize: '15px',
                                                                            color: `${booknowbuttoncolor} !important`,
                                                                            '&:hover': {
                                                                                color: `${booknowbuttonhovercolor} !important`,
                                                                                cursor: 'pointer'
                                                                                }
                                                                            }}
                                                                        onClick={() => handleClear(`code_${index + 1}`)}/>
                                                                    </InputAdornment>
                                                                ),
                                                                }}
                                                            required
                                                            variant="standard"
                                                            />
                                                        </>
                                                        )}
                                                            <Button
                                                                disabled={!(promotionDetail?.subCategories.length === Object.values(promotionFormIk.values?.codeValues).filter(x => x).length) || loader}
                                                                sx={{
                                                                    backgroundColor: `${booknowbuttoncolor} !important`,
                                                                    color: `${buttonFontColor} !important`,
                                                                    opacity: (!(promotionDetail?.subCategories.length === Object.values(promotionFormIk.values?.codeValues).filter(x => x).length) || loader) ? '50%' : '100%',
                                                                    gap: '4px !important',
                                                                    '&:hover': {
                                                                        backgroundColor: `${booknowbuttonhovercolor} !important`,
                                                                        color: `${buttonHoverFontColor} !important`
                                                                    }}}
                                                                    className={ brandID =='ba94d9bb-d072-4fa8-8b84-b51783cd0a55'? 'applyDLF': 'apply' }
                                                                    onClick={ applyPromotion }>
                                                                    {loader && <CircularProgress sx={{ width: "20px!important", height:'10px !important'}} color="inherit" />}
                                                                        APPLY
                                                        </Button>
                                                        </Box>
                                                    </Box>
                                                }
                                                </Box>
                                                {promotionOption.length - 1 !== index && <hr className='vertLine' />}
                                                </>
                                            )
                                        }
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </form>
        </ClickAwayListener>
    )
}
