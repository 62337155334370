
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import DateFnsUtils from "@date-io/date-fns";
// import { LocalizationProvider } from "@material-ui/pickers";
import MobileApp from "./mobileApp";
import 'process/browser';

// Find div With Widget ID
const widgetDiv = document.getElementById('rg-booking-widget') as HTMLElement;
const root = ReactDOM.createRoot(widgetDiv);
const DateTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: '#fff'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'none',
          color: '#fff'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    }
  }
});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={DateTheme}>
    {/* <LocalizationProvider dateAdapter={DateFnsUtils}> */}
    <App symbol={widgetDiv.dataset} />
    {/* <MobileApp /> */}
    {/* </LocalizationProvider> */}
    </ThemeProvider>
  </React.StrictMode>
);
