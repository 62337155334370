import { Divider } from '@mui/material'
import '../widget-control/WidgetStyle.css';

function VerticalDivider() {
  
  return (
    <Divider orientation="vertical" flexItem className='lineHeight' sx={{ backgroundColor: '#FFFFFF !important', boxShadow: 'inset 0px 3px 6px #00000029',  opacity: '0.4', height: '70px !important' }} />
  )
}

export default VerticalDivider