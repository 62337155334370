import { ReactNode } from 'react'
import * as device from 'react-device-detect'

interface DeviceProps {
    children: (props: typeof device) => ReactNode,
    style?: any,
    isMobile?: boolean
}
export default function Device(props: DeviceProps) {
    const {isMobile} = props;
    if(isMobile) {
        return <div className="device-layout-component" style={{width: '98%', overflowX: "hidden", ...props.style }}>{props.children(device)}</div>
    }
    return <div className="device-layout-component" style={{width: '98%', ...props.style }}>{props.children(device)}</div>
}
