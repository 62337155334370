export const formatDateToIOS = (dateString: string): string => {
    const parts = dateString.split('-');
    if(parts[0].length === 4) {
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1; // months are zero-indexed in JavaScript
        const day = parseInt(parts[2]) + 1;

        const date = new Date(year, month, day);
        return date.toISOString().slice(0, 10);
    }
    else {
        const year = parseInt(parts[2]);
        const month = parseInt(parts[0]) - 1; // months are zero-indexed in JavaScript
        const day = parseInt(parts[1]) + 1;

        const date = new Date(year, month, day);
        return date.toISOString().slice(0, 10);
    }
}